import React from 'react';
import PropTypes from 'prop-types';

import CTA from '../components/member-experience/cta';
import Hero from '../components/PDCMEP/pdcmepHero';
import Layout from '../components/layout';
import Statements from '../components/PDCMEP/pdcmepStatements';

const pageDescription =
  'Providers are key influencers in year-round member experience improvement. Improved patient-provider relationships result in improved patient experience, and better health outcomes for patients.';

/**
 * PDCMEP page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <PDCMEP location={string} />
 */

const PDCMEP = ({ location }) => (
  <Layout
    pageTitle="Provider-Driven Member Experience Program"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <Statements />
    <CTA />
  </Layout>
);

PDCMEP.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default PDCMEP;
