import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

const sizing = {
  height: '70vh',
  width: '100vw',
};

/**
 * PDCMEP page Hero component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <PDCMEPHero />
 */

const PDCMEPHero = () => {
  const {
    contentfulPdcmep: {
      heroImage: { fixed },
      heroTitle,
      heroSubtitle,
    },
  } = useStaticQuery(graphql`
    query pdcmepHero {
      contentfulPdcmep {
        heroImage {
          fixed(height: 667, width: 1600, quality: 85, resizingBehavior: FILL) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        heroSubtitle
        heroTitle
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.hero
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.relative,
          base.w100,
          main.heroInner
        )}
      >
        <Img
          fixed={fixed}
          alt="Man in workspace adding stars to wall."
          loading="eager"
          objectPosition="40% top"
          style={sizing}
          imgStyle={sizing}
          className={main.hedisHeroImg}
        />
        <div
          className={cx(base.pa5, base.w100, main.heroCopy, main.heroCopyLg)}
        >
          <p className={cx(base.ma0, base.mb3, theme.colorGrey, theme.title2)}>
            {heroTitle}
          </p>
          <h1 className={cx(main.heading, main.heroTitleTeal, theme.colorGrey)}>
            <CleanHTML html={heroSubtitle} />
          </h1>
        </div>
      </div>
    </section>
  );
};

export default PDCMEPHero;
