import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

const copyImg = {
  maxHeight: '360px',
  maxWidth: '360px',
  minHeight: '316px',
  minWidth: '316px',
};

/**
 * Retention page CTA component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <CTA />
 */

const CTA = () => {
  const {
    contentfulMemberExperience: {
      ctaContent: {
        childMarkdownRemark: { html },
      },
      ctaImage: { fluid },
      ctaTitle,
    },
  } = useStaticQuery(graphql`
    query cahpsMemberExperienceCTA {
      contentfulMemberExperience {
        ctaContent {
          childMarkdownRemark {
            html
          }
        }
        ctaImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        ctaTitle
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyBetween,
        base.w100,
        main.dataItem,
        main.mb90,
        main.mt90
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyBetween,
          base.w100
        )}
      >
        <Img fluid={fluid} alt="" style={copyImg} imgStyle={copyImg} />
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyBetween,
            base.ma2,
            base.w100,
            main.imgCopyWrap
          )}
        >
          <p className={cx(base.mb2, main.subHeading)}>{ctaTitle}</p>
          <div className={main.stCopy}>
            <CleanHTML html={html} />
          </div>
          <Link
            to="/contact"
            className={cx(
              main.button,
              main.buttonAlt,
              main.ctaButton,
              theme.colorWhite,
              theme.shapeBlue
            )}
          >
            Let&apos;s Chat
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CTA;
