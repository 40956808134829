import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Statements from '../statements';

/**
 * PDCMEP page Statements component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <PDCMEPStatements />
 */

const PDCMEPStatements = () => {
  const {
    contentfulPdcmep: {
      enableActionContent,
      enableActionFlipped,
      enableActionImage,
      enableActionSubtitle,
      enableActionTitle,
      createAwarenessContent,
      createAwarenessCTA,
      createAwarenessFlipped,
      createAwarenessImage,
      createAwarenessSubtitle,
      createAwarenessTitle,
      identifyKeyInfluencersContent,
      identifyKeyInfluencersCTA,
      identifyKeyInfluencersFlipped,
      identifyKeyInfluencersImage,
      identifyKeyInfluencersSubtitle,
      identifyKeyInfluencersTitle,
    },
  } = useStaticQuery(graphql`
    query pdcmepStatements {
      contentfulPdcmep {
        enableActionContent {
          childMarkdownRemark {
            html
          }
        }
        enableActionFlipped
        enableActionImage {
          fluid(maxWidth: 800, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        enableActionSubtitle
        enableActionTitle
        createAwarenessContent {
          childMarkdownRemark {
            html
          }
        }
        createAwarenessCTA {
          childMarkdownRemark {
            html
          }
        }
        createAwarenessFlipped
        createAwarenessImage {
          fluid(maxWidth: 800, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        createAwarenessSubtitle
        createAwarenessTitle
        identifyKeyInfluencersContent {
          childMarkdownRemark {
            html
          }
        }
        identifyKeyInfluencersCTA {
          childMarkdownRemark {
            html
          }
        }
        identifyKeyInfluencersFlipped
        identifyKeyInfluencersImage {
          fluid(maxWidth: 800, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        identifyKeyInfluencersSubtitle
        identifyKeyInfluencersTitle
      }
    }
  `);

  const statements = [
    {
      alt: enableActionSubtitle,
      subheading: enableActionSubtitle,
      copy: enableActionContent.childMarkdownRemark.html,
      flipped: enableActionFlipped,
      heading: enableActionTitle,
      image: enableActionImage.fluid,
      even: true,
      xl: true,
    },
    {
      alt: createAwarenessSubtitle,
      subheading: createAwarenessSubtitle,
      copy: createAwarenessContent.childMarkdownRemark.html,
      cta: createAwarenessCTA.childMarkdownRemark.html,
      flipped: createAwarenessFlipped,
      heading: createAwarenessTitle,
      image: createAwarenessImage.fluid,
      bg: true,
      even: true,
      xl: true,
    },
    {
      alt: identifyKeyInfluencersSubtitle,
      subheading: identifyKeyInfluencersSubtitle,
      copy: identifyKeyInfluencersContent.childMarkdownRemark.html,
      cta: identifyKeyInfluencersCTA.childMarkdownRemark.html,
      flipped: identifyKeyInfluencersFlipped,
      heading: identifyKeyInfluencersTitle,
      image: identifyKeyInfluencersImage.fluid,
      even: true,
      xl: true,
    },
  ];

  return <Statements sections={statements} />;
};

export default PDCMEPStatements;
